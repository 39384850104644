import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <article>
      <header>
        <h1>Qurve</h1>
      </header>
      <p>Precision <span>Software</span> Engineering</p>
    </article>
  </Layout>
)

export default IndexPage
